@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .pb-9-16 {
    padding-bottom: 56.25%
  }
}

@layer components {
  .menu-icon {
    @apply w-10 h-10 p-1 stroke-current transition text-purple-700 dark:text-purple-300 group-hover:text-purple-900 dark:group-hover:text-purple-100;
  }
  .navlink {
    @apply text-2xl xl:text-3xl p-3 text-gray-900 dark:text-gray-300 transition hover:text-purple-700 dark:hover:text-gray-100 focus:text-purple-700 dark:focus:text-gray-100;
  }
  .heading {
    @apply text-2xl sm:text-3xl lg:text-4xl font-bold dark:text-gray-300;
  }
  .input {
    @apply border border-gray-700 dark:border-gray-300 border-opacity-50 dark:bg-gray-700 transition dark:focus:bg-gray-500;
  }
  .ratio-9-16-cont {
    @apply relative w-full h-0 pb-9-16;
  }
  .ratio-9-16-item {
    @apply absolute inset-0 w-full h-full;
  }
  .p-resp {
    @apply p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 2xl:p-14;
  }
}
